import Vue from "vue";

function isModalComponent(component) {
  return component && component.$options._componentTag === "a-modal";
}

/**
 * 渲染 <a-modal /> 组件。
 * 默认会注入 $resolve 方法作为额外的 prop，用于关闭 modal
 * @param {import("vue/types/umd").ComponentOptions} Comp modal 组件选项
 * @param {object} props 组件 props
 * @returns {Promise} modal 关闭时会 resolve promise
 */
export default async function(Comp, props) {
  // 支持异步组件
  if (typeof Comp === "function") {
    const asyncComp = await Comp();
    Comp = asyncComp.__esModule ? asyncComp.default : asyncComp;
  }
  return new Promise((resolve, reject) => {
    function hide() {
      const modal = vm.$children[0];
      if (isModalComponent(modal)) {
        modal.$emit("change", false);
      }
    }
    const vm = new Vue({
      mixins: [Comp],
      propsData: {
        ...props,
        // resolve promise 并关闭 modal
        $resolve: value => {
          resolve(value);
          hide();
        },
        $reject: value => {
          reject(value);
          hide();
        },
        $hide: hide
      },
      mounted() {
        const modal = this.$children[0];
        if (!isModalComponent(modal)) {
          return;
        }
        // modal 关闭时，等待动画结束再销毁 vm
        modal.sVisible = true;
        modal.$on("change", visible => {
          modal.sVisible = visible;
          setTimeout(() => this.$destroy(), 300);
        });
      },
      destroyed() {
        reject();
      }
    });
    // antd <a-modal /> 组件自己会将 $el append 到 body
    // 故无需再执行 document.body.append($el)
    vm.$mount();
  });
}
