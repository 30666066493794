/**
 * 保证相同参数的函数上一次执行未完成时，不会执行下一次调用。
 * 用于避免多次调用函数，例如发送初始化请求。
 * @param {Function} fn 目标函数
 * @param {Boolean} once 是否只执行一次，后续所有调用都返回初次调用的结果
 * @returns {Function} 新函数
 */
export function keepState(fn, once = false) {
  // 未 resolve 的 promise
  let state = null;
  return async function(...params) {
    const paramString = JSON.stringify(params);
    if (state && state.params == paramString) {
      return Promise.resolve(state);
    }
    state = Promise.resolve(fn.apply(this, params));
    state.params = paramString;
    if (!once) {
      state.finally(() => (state = null));
    }
    return state;
  };
}

/**
 * 创建一个会缓存 func 结果的函数，类似 lodash.memoize()。区别在于返回的
 * 具备缓存功能的函数，同一时刻、相同缓存 key只会调用一次，适用于异步过程调用。
 * @param {function} fn 需要缓存化的函数
 * @param {function} resolver 如果提供了 resolver ，就用 resolver 的返回值作为 key 缓存函数的结果。 默认情况下用第一个参数作为缓存的 key。
 */
export function memoize(fn, resolver) {
  const cache = new Map();
  const memoFn = keepState(function(...params) {
    const key = resolver ? resolver(...params) : params[0];
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = fn.apply(this, params);
    cache.set(key, result);
    // 如果 result 是一个 rejected promise，则移除缓存内容
    Promise.resolve(result).catch(() => {
      cache.delete(key);
    });
    return result;
  });
  memoFn.cache = cache;
  return memoFn;
}
