/**
 * v-resizable 指令，目前仅支持单侧调整元素宽度，需手动设置指令应用的元素的 position 属性
 * v-resizable.right 容器右侧调整（默认）
 * v-resizable.left 左侧调整
 * @todo v-resizable.top
 * @todo v-resizable.bottom
 */
export const resizable = {
  inserted(el, { arg = "right" }) {
    if (!el) return;
    const resizeBar = document.createElement("div");
    resizeBar.className = "ndl-resize-bar " + arg;
    let startWidth = el.getBoundingClientRect().width;
    let startX = 0;
    const dir = arg === "left" ? -1 : 1;
    function onMousemove(evt) {
      const diffX = (evt.clientX - startX) * dir;
      el.style.width = startWidth + diffX + "px";
    }
    function onMouseup() {
      document.removeEventListener("mousemove", onMousemove);
      document.removeEventListener("mouseup", onMouseup);
      resizeBar.classList.remove("dragging");
    }
    resizeBar.addEventListener("mousedown", evt => {
      evt.preventDefault();
      document.addEventListener("mousemove", onMousemove);
      document.addEventListener("mouseup", onMouseup);
      startX = evt.clientX;
      startWidth = el.offsetWidth;
      resizeBar.classList.add("dragging");
    });
    el.append(resizeBar);
  }
};

export const visible = {
  inserted(el, { value }) {
    if (!value) el.style.visibility = "hidden";
  },
  update(el, { value }) {
    el.style.visibility = value ? null : "hidden";
  }
};

export const focus = {
  inserted(el) {
    // 保险起见延迟 10ms
    if (el) setTimeout(() => el.focus(), 10);
  }
};
