export const RESERVED_PREFIX = "_dataops_";

/**
 * 过滤掉含保留符号的项目，例如“连接”中同步的表、字段中含有特定前缀的条目需要隐藏
 * @param {Array} list 要过滤的列表
 * @param {Function} predicate 获取过滤字符串的方法，接受两个参数 predicate(item, index)，返回一个字符串
 * @return {Array} 过滤后的列表
 */
export default function(list, predicate) {
  predicate = predicate || (item => item.name || "");
  return list.filter((item, index) => {
    const source = String(predicate(item, index)).toLocaleLowerCase();
    return !source.startsWith(RESERVED_PREFIX);
  });
}
